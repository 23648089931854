
import { computed, defineComponent, PropType, reactive, ref } from '@vue/composition-api'
import { mdiAlert } from '@mdi/js'
import { useNotify } from '@/store'

import CommonAddEditDialog from '@/components/common/CommonAddEditDialog.vue'

import { handleError } from '@/utils/handleError'

import { ComplianceCheckId } from '@/api/types/complianceCheck'
import { FileId, FileInput } from '@/api/types/file'
import { useUpdateFile, useUploadFile } from '@/api/file'

export default defineComponent({
  name: 'AddEditFileDialog',
  components: {
    CommonAddEditDialog,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    entityName: {
      type: String,
      default: null,
    },
    entityId: {
      type: Number as PropType<ComplianceCheckId>,
      default: null,
    },
    fileToEditId: {
      type: Number as PropType<FileId>,
      default: null,
    },
  },
  setup: (props, { root, emit }) => {
    const isEditMode = computed(() => Boolean(props.fileToEditId))

    const { addNotification } = useNotify()

    const form = ref<FileInput>({} as FileInput)

    const { uploadFile } = useUploadFile()
    const { updateFile } = useUpdateFile()

    async function onAddFile(): Promise<void> {
      try {
        form.value.referenceId = props.entityId
        form.value.referenceType = props.entityName

        await uploadFile(form.value)

        addNotification({
          text: root.$t('misc.files.form.add.success') as string,
          type: 'success',
          timeout: 3000,
        })

        close()
      } catch (error: unknown) {
        handleError(error)
      }
    }

    async function onEditFile(): Promise<void> {
      try {
        form.value.referenceId = props.entityId
        form.value.referenceType = props.entityName

        await updateFile(props.fileToEditId, form.value)

        addNotification({
          text: root.$t('misc.files.form.update.success') as string,
          type: 'success',
          timeout: 3000,
        })
        close()
      } catch (error: unknown) {
        handleError(error)
      }
    }

    function close(): void {
      emit('added-edited')

      emit('close')
    }

    return reactive({
      icons: {
        mdiAlert,
      },
      state: {
        isEditMode,

        form,
      },
      listeners: {
        onAddFile,

        onEditFile,
      },
    })
  },
})
