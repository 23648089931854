var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('CommonAddEditDialog', {
    attrs: {
      "value": _vm.value,
      "is-edit-mode": _vm.state.isEditMode
    },
    on: {
      "add": function add($event) {
        _vm.state.isEditMode ? _vm.listeners.onEditFile() : _vm.listeners.onAddFile();
      },
      "close": function close($event) {
        return _vm.$emit('close');
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t("misc.files.form.".concat(_vm.state.isEditMode ? 'edit' : 'add', ".title"))) + " ")];
      },
      proxy: true
    }, {
      key: "fields",
      fn: function fn() {
        return [_vm.state.isEditMode ? _c('v-alert', {
          attrs: {
            "dense": "",
            "type": "warning",
            "outlined": "",
            "color": "warning",
            "icon": _vm.icons.mdiAlert,
            "prominent": ""
          }
        }, [_c('v-row', [_c('v-col', {
          staticClass: "text-center"
        }, [_c('span', {
          staticClass: "text-subtitle-2"
        }, [_vm._v(" " + _vm._s(_vm.$t('misc.files.form.edit.warning.text')) + " ")])])], 1)], 1) : _vm._e(), _c('v-file-input', {
          attrs: {
            "label": _vm.$t('misc.files.form.file'),
            "clearable": ""
          },
          model: {
            value: _vm.state.form.file,
            callback: function callback($$v) {
              _vm.$set(_vm.state.form, "file", $$v);
            },
            expression: "state.form.file"
          }
        })];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }